<template>
  <div v-if="!loading">
      <custom-breadcrumb
      :pageTitle="dreamer.name"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>

    <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tabIndex" v-if="dreamer">
              <!-- TAB: main info -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('models.dreamer')}}</span>
                </template>
                <tab-main
                  :canEdit="$store.getters['user/getRole']=='teacher' || dreamer.owner"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- TAB: Ajustes NEE/ PIN -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.settings')}}</span>
                </template>
                <tab-settings
                  :dreamer="dreamer"
                  :nee="nee"
                  :topics="topics"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- TAB: Personalización -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SlidersIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.customization')}}</span>
                </template>
                <customization-component
                  :dataChanged="dataChanged"
                  :dreamers="[dreamer.id]"
                  :isGroup="false"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- TAB: CONTENT RESTRICTION -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.mandatory')}}</span>
                </template>
                <content-restriction-component
                  :info="[dreamer]"
                  :dreamers="[dreamer.id]"
                  :isGroup="false"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>
              
              <!-- TAB: Compartir -->
              <b-tab v-if="dreamer.owner">
                <template #title>
                  <feather-icon
                    icon="Share2Icon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('general.share')}}</span>
                </template>
                <tab-users
                  :dreamer="dreamer"
                  :licenses="dreamer.linked"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Groups -->
              <b-tab v-if="dreamer.owner && this.$store.getters['user/getRole'] != 'teacher'">
                <template #title>
                  <feather-icon
                    icon="SmileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('Groups')}}</span>
                </template>
                <tab-groups
                  :dreamer="dreamer"
                  :groups="dreamer.groups"
                  :all_groups="own_groups"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>
            </b-tabs>
          </b-card>
          <b-button
            variant="outline-secondary"
            type="button"
            @click="hasHistory() 
              ? $router.go(-1) 
              : $router.push({ 
                name: 'school-dreamers-view', 
                params: { 
                  id: school.id,
                  dreamer_id: dreamer.id, 
                } 
              })"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('forms.go_back') }}
          </b-button>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import { getDreamer } from '@/api/routes'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import TabMain from './edit/TabMain.vue'
import TabSettings from './edit/TabSettings.vue'
import TabUsers from './edit/TabUsers.vue'
import TabGroups from './edit/TabGroups.vue'
import CustomizationComponent from '@/views/sl-components/CustomizationComponent.vue'
import ContentRestrictionComponent from '@/views/sl-components/ContentRestrictionComponent.vue'

import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol, BButton
} from 'bootstrap-vue'
export default {
  components:{
    CustomBreadcrumb,
    ContentRestrictionComponent,
    CustomizationComponent,

    TabSettings,
    TabMain,
    TabUsers,
    TabGroups,

    BTab, BTabs, BCard, BAlert, BLink, BRow, BCol, BButton
  },
  data(){
    return {
      loading: true,
      dreamer: {},
      groups:[],
      own_groups:[],
      nee:[],
      topics:[],
      all_groups:[],
      tabIndex:null,

      dataChanged: 0

    }
  },
  mounted(){
    this.getData();
    this.tabIndex = this.$router.currentRoute.params.tab || 0;
  },
  computed:{
    breadcrumb(){
      return [
        {
          text: this.$t('Dreamers'),
          to: 'dreamers'
        },
        {
          text: this.dreamer.name,
          to: 'dreamer-view',
          params:{
            id: this.dreamer.id
          }
        },
        {
          text: this.$t('forms.edit'),
          active: true,
        },
      ]
    },
  },  
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    async getData(){
      this.dataChanged = this.dataChanged +1

        await this.$http.get(getDreamer+'/'+this.$route.params.id).then( response =>{
          if(response.status ==200){
            let userData = this.$store.getters['user/getUserData'];
            
            this.nee = response.data.nee;
            this.topics = response.data.topics;
            this.dreamer = response.data.dreamer;

            
            let gr = []
            let ow_gr = []
            response.data.groups.map(g => {
              if(g.id != 0){
                if(g.id_user == userData.id){
                 ow_gr.push(g)
                } 
                gr.push(g)
              }
            })
            this.groups = gr;
            this.own_groups = ow_gr;

  
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>