<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="$t('Groups')"
          label-for="group"
        >
          <v-select
            v-model="linked_groups"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            :options="all_groups"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        v-if="shared_groups.length>0"
      >
      <p>{{ $t('forms.dreamer_linked_to_shared_groups') }}</p>
      <b-list-group>
        <b-list-group-item
          v-for="g in shared_groups"
          :key="g.id">{{g.name}}
        </b-list-group-item>
      </b-list-group>
      </b-col>
    </b-row>   
    <b-row>
      <b-col>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { editDreamer  } from '@/api/routes'
import {
  BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
  BListGroup, BListGroupItem
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components:{  
    vSelect,
    ValidationProvider, ValidationObserver,
    BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
    BListGroup, BListGroupItem
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    },
    all_groups:{
      type:Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      required,
      linked_groups: [],
      shared_groups:[]

    }
  },
  mounted(){
    /* let lu = []
    this.dreamer.groups.map(x=> {
      if(x.linked){
        lu.push(x)
      }
    }) */
    let userData = this.$store.getters['user/getUserData'];

    let dreamer_ow_gr = []
    let dreamer_shared_gr = []
    this.groups.map( g => {
      if(g.id_user==userData.id){
        dreamer_ow_gr.push(g)
      } else {
        dreamer_shared_gr.push(g)
      }
    })
    this.shared_groups = dreamer_shared_gr;
    this.linked_groups = dreamer_ow_gr;
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    async submitChanges(){
      let data = {
          'id': this.dreamer.id,
          'linked_groups':this.linked_groups,
          'tab':'groups',
      }
      await this.$http.put(editDreamer+'/'+this.dreamer.id, data).then( response => {
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>