<template>
  <div>
    <b-row>
      <b-col>
        <p>{{$t('customize.explanation')}}</p>
      </b-col>
    </b-row>
    <!-- TABLE -->
    <b-row v-if="!showForm">
      <b-col>
          <b-skeleton-table
          v-if="loading_data"
          :rows="5"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
        <b-table small striped bordered responsive style=" margin-top:10px; width: auto;
            max-height: 280px;
            white-space: nowrap;"
            show-empty
            :empty-text="$t('table.empty') "

            :fields="restrictions_fields" 
            :items="restrictions_items">
            <template #cell(restriction)="data">
              <div>
                <div v-if="data.item.date_not_before && data.item.date_not_after" 
                  class="font-small-2 font-weight-bolder">
                  {{$t('customize.from')}} {{ new Date(data.item.date_not_before).toLocaleDateString('') }} {{$t('customize.to')}} {{new Date(data.item.date_not_after).toLocaleDateString('') }}
                </div>
                <div class="font-small-2 ">
                  {{ data.item.start_time.slice(0, -3) }} - {{ data.item.end_time.slice(0, -3) }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ resolveDays(data.item.weekdays) }}
                </div>
              </div>
            </template>
            <template #cell(dreamers)="data">
              <div style="min-width: 100px;">
                <b-avatar-group
                    class="mt-1 mb-1"
                    size="33"
                >
                  <b-avatar
                    v-for="dreamer in sliceDreamers(data.item.dreamers)"
                    :key="dreamer.id"
                    v-b-tooltip.hover.bottom="dreamer.name"
                    :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')"
                    class="pull-up"
                    variant="light"
                  />
                  <h6 class="align-self-center ml-1 mb-0" v-if="data.item.dreamers.length > 5">
                    {{ data.item.dreamers.length - 5 >0 ? '+'+(data.item.dreamers.length - 5): ''}}
                  </h6>
                </b-avatar-group>
              </div>
            </template>
            <template #cell(action)="data">
                <span>
                    <b-button
                        v-b-tooltip.hover.bottom="$t('customize.edit_rule')"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-success"
                        class="btn-icon rounded-circle"
                        @click="editRestriction(data.item)"
                        >
                        <feather-icon icon="Edit3Icon" />
                    </b-button>
                    <b-button
                        v-b-tooltip.hover.bottom="$t('customize.delete_rule')"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="deleteRestriction(data.item.id)"
                        >
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </span>
            </template>

        </b-table>
      </b-col>

    </b-row>
    
    <!-- FORM -->
    <b-card class="background-content" v-else>
      <b-form >
        <div class="row">
            <div class="col-12">
              <h3 style="text-align: center;"> 
                {{ restriction_selected.dreamers ? $t('customize.edit_rule') : $t('customize.new_rule')}}
              </h3>
            </div>
        </div>


        <div>
            <h5 class="mt-1">{{$t('customize.date')}}</h5>
            <p class="mt-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-principal"
                name="check-button"
                switch
                v-model="activeDate"
                style="display:inline;"
              />
              {{$t('customize.permanent_dates')}}
              <br>
              <small> {{$t('customize.permanent_dates_small')}}</small>
            </p>
            <p v-if="err_msg.date"><small style="color:red">{{$t('customize.date_missing')}}</small></p>
        </div>

        <b-row v-if="activeDate">
          <b-col cols="6">   
            <b-form-group
              :label="$t('customize.from')"
              label-for="from-datepicker"
            >
          
                <b-form-datepicker
                    id="from-datepicker"
                    v-model="restriction_selected.date_not_before"
                    class="mb-1"
                    :max="restriction_selected.date_not_after" 
                    :locale="getLocal"
                    start-weekday="1"
                />
            </b-form-group>
          </b-col>
          <b-col cols="6">   
            <b-form-group
              :label="$t('customize.to')"
              label-for="to-datepicker"
            >
                <b-form-datepicker
                    id="to-datepicker"
                    v-model="restriction_selected.date_not_after"
                    class="mb-1"
                    :min="restriction_selected.date_not_before"
                    :locale="getLocal"
                    start-weekday="1"
                />
            </b-form-group>
          </b-col>
        </b-row>

        <h5 class="mt-1">{{$t('customize.time')}}</h5>
        <p v-if="err_msg.time"><small style="color:red">{{$t('customize.'+err_msg.time)}}</small></p>
        <div class="row">

          <!-- time picker -->
            <b-col md="6">
                <b-form-group
                :label="$t('customize.from')"
                >
                <b-form-timepicker
                  id="datepicker-from"
                  placeholder=""
                  no-close-button 
                  v-model="restriction_selected.start_time"
                />
                <!-- <flat-pickr
                    v-model="restriction_selected.start_time"
                    :class="['form-control']"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    style="background-color: white;"
                /> -->
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                :label="$t('customize.to')"
                >
                <b-form-timepicker
                  id="datepicker-to"
                  placeholder=""
                  no-close-button 
                  v-model="restriction_selected.end_time"
                />
                <!-- <flat-pickr
                    v-model="restriction_selected.end_time"
                    :class="['form-control']"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    style="background-color: white;"
                /> -->
                </b-form-group>
            </b-col>

        </div>

        <h5 class="mt-1">{{$t('customize.days')}}</h5>
        <p v-if="err_msg.days"><small style="color:red">{{$t('customize.at_least_one_day')}}</small></p>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox-group
                id="weekdays-checkbox"
                v-model="restriction_selected.weekdays"
                name="weekdays"
                class="demo-inline-spacing"
              >
                <b-form-checkbox 
                  v-for="day in 7" :key="day"
                  :value="day">
                    {{ $t('customize.'+day) }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        
        <!-- RESTRICTION APPLIES TO -->
        <b-row v-if="!restriction_selected.dreamers">
          <template v-if="isGroup">
            <b-col>
              <h5 class="mt-1" >{{$t('customize.children_with_rule')}}</h5>
              <b-avatar-group
                  class="mt-2 pt-50 mb-1"
                  size="33"
              >
                <b-avatar
                  v-for="group in info"
                  :key="group.id"
                  v-b-tooltip.hover.bottom="group.id==0?$t('table.no_group'):group.name"
                  :src="require('@/assets/images/avatar/groups/' + (group.avatar || group.id_avatar || 'multi') + '.png')"
                  class="pull-up"
                  variant="light"
                  rounded
                />
              </b-avatar-group>
            </b-col>
          </template>
          <template v-else>
            <b-col>
              <h5 class="mt-1" >{{$t('customize.children_with_rule')}}</h5>
              <b-avatar-group
                  class="mt-2 pt-50 mb-1"
                  size="33"
              >
                <b-avatar
                  v-for="dreamer in info"
                  :key="dreamer.id"
                  v-b-tooltip.hover.bottom="dreamer.name"
                  :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')"
                  class="pull-up"
                  variant="light"
                />
              </b-avatar-group>
            </b-col>
          </template>
        </b-row>
        <b-row v-else>
          <b-col>
            <h5 class="mt-1" >{{$t('customize.children_with_rule')}}</h5>
            <b-avatar-group
                class="mt-2 pt-50 mb-1"
                size="33"
            >
              <b-avatar
                v-for="dreamer in restriction_selected.dreamers"
                :key="dreamer.id"
                v-b-tooltip.hover.bottom="dreamer.name"
                :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')"
                class="pull-up"
                variant="light"
              />
            </b-avatar-group>
          </b-col>
        </b-row>

      </b-form>
    </b-card>

    <!-- BTNS -->
    <b-row>
      <b-col class="text-center">
        <b-button v-if="showForm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            @click="submitChanges"
            variant="primary"
            class="mr-1"
          >
            {{$t('forms.save')}}
          </b-button>
        <b-button
          :variant="showForm?'secondary':'success'"
          type="button"
          class="mr-1"
          @click="refreshForm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <feather-icon
            :icon="showForm ? '' : 'PlusIcon'"
            size="16"
          /> {{ showForm?$t('forms.cancel'):$t('customize.new_rule') }}
        </b-button>
      </b-col>
    </b-row>
    
  </div>
</template>

<script>
import { contentRestriction } from '@/api/routes'

import {
  BCard, BCardText, BTable, BSkeletonTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,BFormCheckboxGroup, BFormTimepicker,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        flatPickr, 
        BCard, BCardText, 
        BFormDatepicker, BFormTimepicker,
        BTable, BSkeletonTable,
        BButton, BRow, BCol, 
        BFormCheckbox,BFormCheckboxGroup,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    props:{
      dreamers:{
        type: Array,
        required: true,
      },
      isGroup:{
        type: Boolean,
        required: true,
      },
      info:{
        type: Array,
        required: true
      }
    },
    data(){
      return {
        max:'',
        min:'',

        loading_data: false, 
        showForm:false,

        Selected: false,

        activeDate: false,
        days: [],
        
        restrictions_items: [],
        restriction_selected:{},
        dreamers_ids_corrected:[],

        err_msg:{
          date:null,
          time:null,
          days:null
        },
        show_error:false,
        isEdit:false,
      }
  },
  mounted(){
    this.getData()
    let isNumber = typeof this.dreamers[0] === "number"
    if(isNumber){
      this.dreamers_ids_corrected = this.dreamers    
    } else {
      this.dreamers_ids_corrected = this.dreamers.map(x => x.id)
    } 
    // console.log("--------------selected_data", isNumber, this.dreamers_ids_corrected)
  },
  computed:{
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    restrictions_fields(){
      return  [
          { key: 'restriction', label: this.$t('customize.mandatory') },
          { key: 'dreamers', label: this.$t('customize.children_with_rule') },
          { key:'action', label:this.$t('fields.action')},
      ]
    },
  },
  methods:{
    refreshForm(){
      this.err_msg = {
          date:null,
          time:null,
          days:null
        }
      this.activeDate = false
      this.restriction_selected = {}
      this.showForm = !this.showForm
      this.isEdit=false
    },
    resolveDays(weekdays){
      let wd = [...weekdays]
      wd.sort()
      let days = []
      wd.forEach(d => {
        days.push(this.$t('customize.'+d))
      });
                
      return days.join(', ')
    },
    editRestriction(restriction){
      this.restriction_selected = restriction
      if(restriction.date_not_before && restriction.date_not_after){
        this.activeDate = true
      }
      this.isEdit = true
      this.showForm = true
    },
    sliceDreamers(dreamers){
      if(dreamers.length>5){
        return dreamers.slice(0, 5)
      }
      return dreamers
    },
    async getData(){
      let dreamers_ids = this.dreamers;
      // console.log(dreamers_ids, "dreamers_ids")
      let data = {
          'isGroup': this.isGroup,
          'ids': dreamers_ids,
      };
      
      
      await this.$http.post(contentRestriction, data).then( response => {
        if(response.status == 200){
        //CUSTOMIZATION DATA
          this.restrictions_items = response.data.restrictions //array de las restricciones de contenidos de los dreamers especificados.
        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    validate(restriction){
      let valid = true
      if(this.activeDate){
          if(!restriction.date_not_after || !restriction.date_not_before){
            valid = false
            this.err_msg.date = true
          } else {
            this.err_msg.date = false
          }
      }  else {
        this.err_msg.date = false
      }

      if(!restriction.start_time || !restriction.end_time){
        valid = false
        this.err_msg.time = 'time_missing'
      } else {
        this.err_msg.time = ''
        if(restriction.end_time <= restriction.start_time){
          valid = false
          this.err_msg.time = 'wrong_time'
        } else {
          this.err_msg.time = ''
        }
      }

      if(!restriction.weekdays || restriction.weekdays.length == 0){
        valid = false
        this.err_msg.days = true
      } else {
        this.err_msg.days = false
      }
      return valid
    },
    deleteRestriction(restriction_id){
      this.$bvModal
        .msgBoxConfirm(this.$t('customize.delete_content_restriction_confirmation'), {
          title: this.$t('customize.delete_rule'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$http.delete(contentRestriction+restriction_id).then( response =>{
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    async submitChanges(){
      if(this.validate(this.restriction_selected)){
        this.show_error = false
        let restriction = {...this.restriction_selected}
        if(!restriction.id){
          restriction.id = null;
        }
        if(!this.activeDate){
          restriction.date_not_after = null;
          restriction.date_not_before = null;
        } else {
          if(restriction.date_not_after.length >10){
            let start_date = restriction.date_not_after.substring(0, 10)+' 23:59:59';
            restriction.date_not_after = start_date
          } else {
           restriction.date_not_after = restriction.date_not_after+' 23:59:59';
          }
          if(restriction.date_not_before.length >10){
            let end_date = restriction.date_not_before.substring(0, 10)+' 00:00:00';
            restriction.date_not_before = end_date
          } else {
            restriction.date_not_before = restriction.date_not_before+' 00:00:00'; 
          }
        }
        
        if(restriction.start_time.length <8){
          restriction.start_time = restriction.start_time+':00';
        }
        if(restriction.end_time.length <8){
          restriction.end_time = restriction.end_time+':00';
        }
        let dreamers_ids = this.dreamers_ids_corrected
        if(this.restriction_selected.dreamers){
            dreamers_ids  = this.restriction_selected.dreamers.map(x => x.id)
        }
        restriction.weekdays.sort()
        let data = {
          'isGroup': this.isEdit ? false : this.isGroup,
          'ids': dreamers_ids,
          'restriction': restriction
        } 
        await this.$http.put(contentRestriction, data).then( response => {
          if(response.data.status == 200){
            this.getData()
            this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
            this.refreshForm()
          } else {
            this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      
      } else {
        this.show_error = true
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/bootstrap-extended/include';

/* MODAL Personalizar */
.background-content {
  background: #f2f1f1
}
body.dark-layout .background-content{
  background-color: rgb(39, 42, 58);
}

.demo-inline-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.is-valid{
      border-color: #29c771;
}
.is-not-valid{
      border-color: red;
}
</style>